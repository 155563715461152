import {
    CTable,
    CTableBody,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CTableDataCell,
  } from "@coreui/react";
  import { Skeleton } from "@mui/material";
  import React from "react";
  
  export default function Loader({ datacol }) {
    const renderTableHeaders = () => {
      const headers = [];
      for (let i = 0; i < datacol; i++) {
        headers.push(
          <CTableHeaderCell key={i} scope="col">
            <Skeleton />
          </CTableHeaderCell>
        );
      }
      return headers;
    };
  
    const renderTableRows = () => {
      const rows = [];
      for (let i = 0; i < 8; i++) { 
        rows.push(
          <CTableRow key={i}>
            {renderTableDataCells(datacol)}
          </CTableRow>
        );
      }
      return rows;
    };
  
    const renderTableDataCells = (count) => {
      const cells = [];
      for (let i = 0; i < count; i++) {
        cells.push(
          <CTableDataCell key={i}>
            <Skeleton />
          </CTableDataCell>
        );
      }
      return cells;
    };
  
    return (
      <CTable>
        <CTableHead>
          <CTableRow>
            {renderTableHeaders()}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {renderTableRows()}
        </CTableBody>
      </CTable>
    );
  }
  
import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import { CButton, CRow, CCol, CSpinner } from "@coreui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RenewalImage from '../../assets/images/renewal-img.jpg';

function RenewalReminderPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [isRenewalReminder, setIsRenewalReminder] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedPlanEndDate = sessionStorage.getItem("planEndDate");
    console.log("Checking plan end date:", storedPlanEndDate);
  
    if (storedPlanEndDate) {
      const planEndDate = new Date(storedPlanEndDate);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      console.log("Plan end date:", planEndDate, "Today's date:", today);

      if (planEndDate < today) {
        console.log("Plan has expired. Showing renewal reminder.");

        setIsRenewalReminder(true);
      } else {
        console.log("Plan is valid. Redirecting to dashboard.");
      
        navigate("/dashboard");
      }
    } else {
      console.log("No plan end date found. Redirecting to dashboard.");
    
      navigate("/dashboard");
    }
  }, [navigate]);
  
  const handleRenewNow = () => {
    setLoading(true);
    console.log("Renew process started...");


    setTimeout(() => {
      const res = { data: { isBlocked: false } };
      console.log("API response:", res);

      if (res.data.isBlocked) {
        console.log("Account is blocked. Redirecting to /mywallzehspace.");

        window.location.href = "/mywallzehspace";
      } else {
        console.log("Account is not blocked. Redirecting to dashboard.");

        //toast.success("Renewal successful! Redirecting to the dashboard...");
        navigate("/dashboard");
      }
      setLoading(false);
    }, 2000);
  };

  if (!isRenewalReminder) {
    console.log("Directly navigating to dashboard.");
  
    navigate("/dashboard");
    return null;
  }


  return (  
    <>
    <ToastContainer />
    {loading ? (
      <div style={{ height: "30rem" }}>
        <CSpinner style={{ position: "absolute", top: "50%", left: "48%" }} />
      </div>
    ) : (
      <div className="renewalReminderPage" style={{  background:"rgba(255, 255, 255, 0.95)"}}>
        {/* <CCol md={6} className="p-4 d-flex flex-column align-items-center form-container"> */}
          <img src={RenewalImage} className="RenewalImage" alt="Renewal Reminder" />
          <h2 className="renewalReminder-heading">Action Required: Subscription Expired</h2>
          <p className="renewalReminder-para">
            Your subscription has ended, and you’re just 3 days away from service disruption. Renew now to stay seamlessly connected and enjoy uninterrupted access to all features. Don’t let your access lapse—act quickly to keep everything running smoothly. Renew today and stay ahead with no interruptions!
          </p>
          <button
            color="dark"
            shape="rounded-pill"
            className="form-inputGroup_Button renewalReminder-button"
            onClick={handleRenewNow}
          >
            Renew Now
          </button>
     </div> 
    )}
  </>
);
}
export default RenewalReminderPage;

import React, { useState, useEffect } from "react";
import "./ConfirmBox.scss";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { CSpinner } from "@coreui/react";

const ConfirmBox = ({ message, onConfirm, onCancel }) => {
  const [isVisible, setIsVisible] = useState(false);
  const loader =  useSelector((state) => state.dashboard.commonLoader)

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleConfirm = () => {
    setIsVisible(false);
    onConfirm();
  };

  const handleCancel = () => {
    setIsVisible(false);
    onCancel();
  };

  return (
    <div className={`confirm-box ${isVisible ? "visible" : ""}`}>
      <div className="box">
        <p>{message}</p>
        <div className="button-container">
          <Button variant="outlined" onClick={handleConfirm}  size="medium">
            {
              loader ? <CSpinner/> :  "Confirm"
            }
          </Button>
          <Button variant="outlined" color="error" onClick={handleCancel} size="small">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;

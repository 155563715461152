import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CHeader,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import {
  faArrowUpFromBracket,
  faEllipsisH,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import sortArrowsIcon from "../../assets/icons/Sorting Arrows.svg";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import closeImg from "../../assets/images/drawings/close 1.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import Loader from "../loader/loader";
import API_URL from "src/utils/urls";
import { useNavigate, Link } from "react-router-dom";

import  InfoIcon  from '../../assets/icons/information.png';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

  // Function to toggle tooltip visibility
  const handleTableTooltipToggle = (index) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

function TermsAndConditions() {
  const adminId = useSelector((state) => state.dashboard.accessToken);
  const [terms, setTerms] = useState([]);
  const [termsFile, setTermsFile] = useState(null);
  const [termsName, setTermsName] = useState("");
  const [termsId, setTermsId] = useState(null);
  const [termsFileName, setTermsFileName] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [pageload, setPageLoad] = useState(true);

  const [viewType, setViewType] = useState('table'); 
  const [activeFile, setActiveFile] = useState(null); 
  const [fileContent, setFileContent] = useState('');

  const handleFileClick = (file) => {
    setActiveFile(file);  // Set clicked file as active
  };
  
  const handleToggleChange = (event) => {
    const isChecked = event.target.checked; 
    setViewType(isChecked ? 'table' : 'file'); // Set 'table' when checked, 'file' otherwise
    if (isChecked) {
      setActiveFile(null); 
    } else {
      const active = terms[0]; 
      setActiveFile(active);
    }
  };

  // confirmation model for add terms
  const [openConfirmationModal, setOpenConfirmationModel] = useState(false);
  const handleClickOpenConfirmationModel = () => {
    setOpenConfirmationModel(true);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModel(false);
  };

  // confirmbox
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [confirmDeleteIndex, setConfirmDeleteIndex] = useState(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);
  const [confirmDeleteFilename, setConfirmDeleteFilename] = useState(0);
  const handleConfirm = () => {
    console.log("Confirmed");
    setShowConfirmBox(false);
    setTermsId(confirmDeleteId);
    removeFromArray(terms, confirmDeleteIndex);
  };
  const handleCancel = () => {
    console.log("Cancelled");
    setShowConfirmBox(false);
  };

  const postTermsData = async () => {
    const formData = new FormData();
    formData.append("AID", adminId);
    formData.append("file", termsFile);
    await axios
      .post(`${API_URL}saveTermsConditions`, formData)
      .then(function (response) {
        console.log(response);
        if (response.data.Result == "Failure") {
          toast.error("The Specified Blob Already Exists", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setButtonLoader(false);
          setTermsName("");
          setIsOpen(false);
          setTerms([
            ...terms,
            {
              name: termsName,
              last_update: new Date(),
            },
          ]);
        } else {
          toast.success("Terms And Conditions Saved Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsOpen(false);
          setButtonLoader(false);
          setTermsName("");
          setTerms([]);
          setSkip(0);
          pageload && getTermsAndConditions();
          setPageLoad(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setButtonLoader(false);
        alert(error);
      });
  };
  //  scrollnew
  // const [items, setItems] = useState([]);

  const getTermsAndConditions = async () => {
    try {
      const response = await axios.get(`${API_URL}termsConditions`, {
        params: {
          AID: adminId,
          skip: skip,
          limit: 10,
        },
      });
      setFirstApiLoader(false);
      const newItems = response.data;
      response.data.length && setPageLoad(false);
      if (newItems.length === 0) {
        setHasMore(false);
      } else {
        const result = JSON.stringify(response.data);
        const parsedResult = JSON.parse(result);
        // setTerms(parsedResult);
        setTerms([...terms, ...parsedResult]);
        setSkip(skip + 10);
      }
    } catch (error) {
      setFirstApiLoader(false);
      console.error("Error fetching terms and conditions:", error);
    }
  };

  const deleteTermsFile = async () => {
    await axios({
      method: "POST",
      url: `${API_URL}deleteTerms`,
      data: {
        AID: adminId,
        // TCID: termsId,
        // filename: termsFileName,
        TCID: confirmDeleteId,
        filename: confirmDeleteFilename,
      },
    }).then(
      (res) => {
        // alert("Terms and Conditions deleted successfully");
        toast.success("Terms And Conditions Deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTermsId(null);
        console.log(res);
      },
      (err) => {
        console.log(err);
        // alert(err);
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const readTerms = async () => {
    await axios
      .get(terms)
      .then((response) => {
        const result = JSON.stringify(response.data);
        console.log("terms file", result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addTerms = (e) => {
    const targetFiles = e.target.files[0];
    setTermsFile(targetFiles);
    setTermsName(e.target.files[0].name);
  };

  const submitTermsData = (e) => {
    e.preventDefault();
    if (termsFile != null) {
      setButtonLoader(true);
      postTermsData();
    } else {
      handleClickOpenConfirmationModel();
    }
  };

  const removeFromArray = (array, index) => {
    var id = array.indexOf(index);
    if (id == -1) {
      array.splice(index, 1);
    }
    setTerms(array);
  };

  useEffect(() => {
    if (terms != "") {
      console.log(terms);
    }
  }, [terms]);

  useEffect(() => {
    if (skip == 0) {
      setFirstApiLoader(true);
      setHasMore(true);
      getTermsAndConditions();
    }
  }, [skip]);

  useEffect(() => {
    if (termsId != null) {
      deleteTermsFile();
    }
  }, [termsId]);

  useEffect(() => {
    if (activeFile) {
      fetchFileContent(activeFile);
    }
  }, [activeFile]);

  const fetchFileContent = async (filePath) => {
    try {
      const response = await fetch(filePath); // Fetching file content
      console.log(response.headers.get('Content-Type'));
      if (response.ok) {
        const text = await response.text(); 
        setFileContent(text);
      } else {
        setFileContent('Error fetching file content');
      }
    } catch (error) {
      console.error('Error fetching file:', error);
      setFileContent('Error fetching file content');
    }
  };
  

  // Accordion
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  // firstApiLoader
  const [firstApiLoader, setFirstApiLoader] = useState(false);

  return (
    <div>
      {showConfirmBox && (
        <ConfirmBox
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <ToastContainer />
      <div>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <h2 className="page-header mb-0">Terms And Conditions
          <Tooltip title={
              <div className="custom-tooltip-content" 
              style={{ backgroundColor: '#303030', color: "white",
               margin:"5px",padding:"15px", fontFamily: "Nunito", width: "400px",
              height: "auto", fontSize: "14px",justifyContent:"center",  alignItems: "center" , borderRadius: "10px", 
               
                }}>
               

                <p>
                You can upload your company's terms and conditions here for client visibility. These terms will be accessible to your clients directly through their mobile login. Ensure they are up-to-date so your clients are always informed.
                </p>


            </div>
            }
            arrow
            placement="right"            
            >
              <img src={InfoIcon} 
              alt="Info Icon" 
            style={{ marginLeft: "10px", 
            width: "15px", height: "15px", 
            cursor: "pointer", background: "#ebedef" 
            }} />
          </Tooltip>
          </h2>
          
          <div className="d-flex align-items-center">
        <Switch 
          checked={viewType === 'table'}
          onChange={handleToggleChange}
          color="black"
          
          inputProps={{ 'aria-label': 'toggle view' }}
        />
         <span>{viewType === 'table' ?  "View Active File" : "View Table" }</span>
        </div>

          <div className="add-btn-doc my-3" onClick={toggleAccordion}>
            + Add Terms and conditions
          </div>
        </div>
        
        {isOpen && (
          <div className="card mb-3">
            <CCardBody>
              <form onSubmit={submitTermsData}>
                <div className="d-flex flex-wrap align-items-end">
                  <TextField
                    id="standard-basic"
                    label="Terms Name"
                    variant="standard"
                    className="accordion-textField me-3 mt-3"
                    // required
                    // disabled
                    value={termsName}
                    onChange={(e) => setTermsName(e.target.value)}
                    required
                  />
                  <div className="d-flex lex-column">
                    <input
                      type="file"
                      accept=".txt"
                     //accept=" accept="image/*,application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      onChange={addTerms}
                      // multiple
                    />
                    <label
                      htmlFor="contained-button-file"
                      className="photos-upload_label"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className="file-uploadBtn"
                      >
                        <FontAwesomeIcon
                          icon={faArrowUpFromBracket}
                          className="file-uploadIcon"
                        />
                        Choose a File
                      </Button>
                      {/* {"Photos (upto 5)"} */}
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  <CButton
                    color="light"
                    shape="rounded-0"
                    className="accordion-saveBtn generalDoc-cancelBtn me-3"
                    onClick={() => {
                      //   setFile();
                      setFileContent("");    // Reset file content
                      setTermsName("");  
                      setFileName("");
                      setTermsName("");
                      setTermsFile(null);
                      toggleAccordion();
                    }}
                  >
                    Cancel
                  </CButton>
                  
                  <CButton
                    color="dark"
                    shape="rounded-0"
                    className="accordion-saveBtn generalDoc-uploadBtn"
                    type="submit"
                    disabled={termsFile == null ? true : false}
                  >

                    {buttonLoader ? <CSpinner /> : "Upload"}
                  </CButton> 
                   </div>
                  </form>                                          
            </CCardBody>
          </div>
        )}
      </div>
      
      
  {viewType === 'file' ? (
  <CCard className="active-file">
    <CCardBody>
      {firstApiLoader ? (
        <Loader datacol={4} />
      ) : (
        <InfiniteScroll
          className="file-overflow"
          height={"65vh"}
        padding={"15px"}
          dataLength={terms.length}
          next={getTermsAndConditions}
          hasMore={hasMore && terms.length >= 10}
          loader={terms.length >= 10 ? (
            <p className="text-center">Loading...</p>
          ) : null}
        >
          <div>

            {activeFile ? (
              <div>
                <h3>{activeFile.name}</h3>
                <p style={{ borderBottom:'1px solid rgb(204,204,204)'}}>Uploaded on: {moment(activeFile.updatedDate).format("Do MMM YYYY")}</p>
                {/* <div style={{ overflowY: 'auto', maxHeight: '400px', border: '1px solid #ccc', padding: '10px' }}> */}
                <div style={{  padding: '15px',  overflow:'auto'}}> 
                   <p style={{ textAlign: 'justify'}}>

                     <h6>1. ACCEPTING THE TERMS OF SERVICE</h6>
                         Please read these Terms of Service and our Community Guidelines (collectively, the "AGREEMENT") carefully before using tumblr.com (the "SITE") and/or the other domains, websites, products, applications, mobile applications, services, and/or Content provided by Tumblr, Inc. (all of those collectively with the Site, the "SERVICES") (Tumblr, Inc., a Delaware corporation and wholly-owned subsidiary of Automattic Inc., a Delaware corporation ("AUTOMATTIC"), collectively with its agents, representatives, consultants, employees, officers, and directors, "TUMBLR," "WE," or "US"). By using or accessing the Services, you agree to be bound by all the terms and conditions of this Agreement. If you don't agree to all the terms and conditions of this Agreement, you shouldn't, and aren't permitted to, use the Services. Tumblr’s personalized Services cannot be provided and the terms of this Agreement cannot be performed without Tumblr processing information about you and other users. Processing of the information you share with Tumblr is essential to the personalized Services which we provide and which you expect, including personalized Content (as described below) and ads, and is a necessary part of our performance of the agreement we have with you.
                          We’re just letting you know, here, that Tumblr is now part of Automattic , a technology company that empowers people to build beautiful websites, tell their stories, and find and grow their audience and these Terms of Service , which apply to your use of Tumblr, have been updated to reflect this change. This section also includes an agreement where you're agreeing to agree to the terms of this Agreement.
                          <br />
                          <br />
                  
                          Tumblr is a U.S. company and subject to U.S. laws and jurisdiction. The original version of these Terms of Service (and any other terms, policies, or guidelines that we provide to you) are written in English. To the extent any translated version of these Terms of Service (or any other terms, policies, or guidelines that we provide to you) conflicts with the English version, the English version controls.
                          THESE TERMS OF SERVICE CONTAIN LIMITATIONS OF TUMBLR'S LIABILITY IN SECTION 15.
                          Hello! Welcome to Tumblr’s Terms of Service. Please read this carefully before using our site, services, or products. This is a contract between you and Tumblr. We've also included several annotations that aren't a part of the contract itself, but are intended to emphasize key sections and help you follow the text. We've tried to be fair and straightforward. Please feel free to contact us (https://www.tumblr.com/support) if you have any questions or suggestions!
                          <br />
                          <br />
                          <h6>2. MODIFICATIONS TO THIS AGREEMENT</h6>
                            Tumblr reserves the right to modify these Terms of Service or our Community Guidelines by providing notice to you.  We will provide notice to you by posting a revised version of the modified Terms of Service or Community Guidelines (https://www.tumblr.com/policy/community) on the Services, and, in some cases we may provide additional notice to you such as via email, or otherwise through the Services (such as through a notification on your Tumblr Dashboard or in our mobile applications). Modifications will not apply retroactively. You are responsible for reviewing and becoming familiar with any modifications to this Agreement.
                            We may sometimes ask you to review and to explicitly agree to (or reject) a revised version of this Agreement. In such cases, modifications will be effective at the time of your agreement to the modified version of this Agreement. If you do not agree at that time, you are not permitted to use the Services.
                            In cases where we do not ask for your explicit agreement to a modified version of this Agreement, but otherwise provide notice as set forth above, the modified version of this Agreement will become effective fourteen days after we have posted the modified Agreement and provided you notification of the modifications. Your use of the Services following that period constitutes your acceptance of the terms and conditions of this Agreement as modified. If you do not agree to the modifications, you are not permitted to use, and should discontinue your use of, the Services.
                            Note that, if you have prepaid for any Paid Services (as defined below) prior to a modification of this Agreement, your use of such prepaid Paid Services is governed by the version of this Agreement in effect at the time Tumblr received your prepayment.
                          As Tumblr grows and improves, we might have to make changes to these Terms of Service. When we do, we'll let you know. We're also going to make it a practice to post old versions so it's easy to see changes/additions/deletions. To see old versions, scroll down to the end of this document.



                   </p>
                    {/* <pre>{fileContent || 'Loading content...'}</pre> */}
                </div>
                {/* Uncomment the line below to enable download link */}
                {/* <a href={activeFile.path} target="_blank" rel="noopener noreferrer">Download</a> */}
              </div>
            ) : (
              <p>No active file selected.</p>
            )}
            
          </div>
        </InfiniteScroll>
      )}
    </CCardBody>
  </CCard>
) : null}

      {/* table list */}

       {viewType !== 'file' && ( 

      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol>
              {firstApiLoader ? (
                <Loader datacol={4} />
              ) : (
                <InfiniteScroll

                className="table-overflow"
                height={"65vh"}
                  dataLength={terms.length}
                  next={getTermsAndConditions}
                  hasMore={hasMore && terms.length >= 10}
                  loader={
                    terms.length >= 10 ? (
                      <p className="text-center">Loading...</p>
                    ) : null
                  }
                  // endMessage={
                  //   terms.length < 10 ? (
                  //     <p className="text-center">No more items to load</p>
                  //   ) : null
                  // }
                >                
                  <CTable align="middle" className="mb-0" responsive>
                    <CTableHead className="projectList-table_header">
                      <CTableRow>
                        <CTableHeaderCell className="projectList-table_header-rowcell">
                          S.No
                        </CTableHeaderCell>
                        <CTableHeaderCell className="projectList-table_header-rowcell">
                          File Name        
                        </CTableHeaderCell>
                        <CTableHeaderCell className="text-center projectList-table_header-rowcell">
                          Upload on
                        </CTableHeaderCell>
                        <CTableHeaderCell className="text-center projectList-table_header-rowcell">
                          Action
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {terms.map((item, index) => (
                        <CTableRow
                          v-for="item in tableItems"
                          key={index}
                          className="projectList-table_row"

                          onClick={() => {
                            setActiveFile(item); // Set the clicked file as active
                            fetchFileContent(item.path); // Fetch content of the clicked file
                          }}

                        >
                          <CTableDataCell className="table-datacell text-start">
                            {index + 1}
                          </CTableDataCell>
                          <CTableDataCell className="table-datacell table-datacell_title">
                             <a
                              href={`${item.path}`}
                              target="blank"
                              className="table-datacell_file"
                            >
                              {item.name}
                              <span
                                className={`${
                                  index === 0
                                    ? "table-datacell_file-active"
                                    : ""
                                }`}
                              >
                                {index === 0 ? "  [ACTIVE]" : ""}
                              </span>
                            </a>  

                          </CTableDataCell>

                          <CTableDataCell className="text-center table-datacell">
                            {/* {moment(item.last_update)
                              .utc()
                              .format("YYYY-MM-DD")} */}
                            {moment
                              .tz(item.updatedDate, "Asia/Kolkata")
                              .format("Do MMM YYYY")}
                          </CTableDataCell>
                          <CTableDataCell className="text-center table-datacell">
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowConfirmBox(true);
                                setConfirmDeleteIndex(index);
                                setConfirmDeleteFilename(item.name);
                                setConfirmDeleteId(item.TCID);
                              }}
                            />
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>

                  
                </InfiniteScroll>                
              )}
              
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
       )} 

      <Dialog
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        className="uploadFilesHandlingModal"
      >
        <DialogContent style={{ margin: "auto" }}>
          Please select file to add terms and conditions
        </DialogContent>
        <img
          src={closeImg}
          className="drawings-modal_closeIcon"
          onClick={handleCloseConfirmationModal}
        />
      </Dialog>      
    </div>
    // </div>
  );
}
export default TermsAndConditions;

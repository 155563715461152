import { CButton, CCol, CRow, CSpinner, CModal, CModalBody, CModalFooter } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Sliders from "./Slider";
import {
  faCircleUser,
  faEye,
  faEyeSlash,   
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import pwdImage from '../../assets/images/pwd-image.jpg';

import {
  setAccessToken,
  setPlan,
  setPlanAdditionalLicenses,
  setPlanEndDate,
  setPlanGBAvailable,
  setPlanGBUsed,
  setPlanIsBlocked,
  setPlanLastPaymentDate,
  setPlanStartDate,
  setUserName,
} from "src/redux/slices/DashboardSlice";
import { ToastContainer, toast } from "react-toastify";
import API_URL from "src/utils/urls";
import { setLoginPage } from "src/redux/slices/LoginSlice";
import cryptoJs from "crypto-js";
import { Phone } from "@mui/icons-material";
import RenewalReminderPage from "./RenewalRemainderPage";

function SignIn() {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [pwdState, setPwdState] = useState(true);
  const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   
  const [showRenewalReminder, setShowRenewalReminder] = useState(false);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);

  const togglePwdState = () => {
    setPwdState(!pwdState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    getData();
    // Checkmail();
  };

  const handleForgotPassword = () => {
    dispatch(setLoginPage("forgotPassword"));
  };

  // const Checkmail = () => {
  //   let params = {
  //     email: mail,
  //   };
  //   try {
  //     axios
  //       .get("https://wallzehnapi.azurewebsites.net/checkEmail", {
  //         params: params,
  //       })
  //       .then((response) => {
  //         setLoading(false);
  //         const newItems = response.data.result;
  //         if (newItems == "no") {
  //           toast.error("Please Enter Correct Mail/Password", {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         } else {
  //           getData();
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         console.error("Error fetching data:", error);
  //       });
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("An unexpected error occurred:", error);
  //   }
  // };

  // function has SHA512 hash string
  const sha512 = (value) => {
    const hash = cryptoJs.SHA512(value);
    return hash.toString(cryptoJs.enc.Hex);
  };

  const getData = async () => {
    try {
      await axios({
        method: "POST",
        url: `${API_URL}login`,
        data: {
          user: mail,
          password: sha512(password),
        },
      }).then(
        (res) => {
          const result = JSON.stringify(res.data.result);
          const parsedResult = JSON.parse(result);
          if (parsedResult === "Success") {
            localStorage.setItem("AID", res.data.ID);
            localStorage.setItem("UName", res.data.name);
            localStorage.setItem("userRole", res.data.role);
            localStorage.setItem("parentId", res.data.parent);
            localStorage.setItem("phone", res.data.mobile);
            localStorage.setItem("mail", res.data.email);
            localStorage.setItem("verified", res.data.verified);

            // plans
            localStorage.setItem("plan", res.data.plan.plan);
            localStorage.setItem("planStartDate", res.data.plan.startdate);
            localStorage.setItem("planEndDate", res.data.plan.enddate);
            localStorage.setItem("planGBAvailable", res.data.plan.GBAvailable);
            localStorage.setItem("planGBUsed", res.data.plan.GBUsed);
            localStorage.setItem(
              "planAdditionalLicenses",
              res.data.plan.additionalLicenses
            );
            localStorage.setItem("planIsBlocked", res.data.isBlocked);
            localStorage.setItem(
              "planLastPaymentDate",
              res.data.plan.lastPaymentDate
            );

            console.log("session storage in login", res.data);
            dispatch(setAccessToken(res.data.AID));
            dispatch(setUserName(res.data.name));
            dispatch(setPlan(res.data.plan.plan));
            dispatch(setPlanStartDate(res.data.plan.startdate));
            dispatch(setPlanEndDate(res.data.plan.enddate));
            dispatch(setPlanGBAvailable(res.data.plan.GBAvailable));
            dispatch(setPlanGBUsed(res.data.plan.GBUsed));
         
            dispatch(
              setPlanAdditionalLicenses(res.data.plan.additionalLicenses)
            );
            dispatch(setPlanIsBlocked(res.data.isBlocked));
            dispatch(setPlanLastPaymentDate(res.data.plan.lastPaymentDate));

          // Check if subscription has expired
          const planEndDate = new Date(sessionStorage.getItem("planEndDate"));
          const today = new Date();
         
          if (planEndDate <today) {
            setShowRenewalReminder(true);  
           return;
          }

        // Redirect based on the account verification status and isBlocked flag
        if (res.data.isBlocked) {
          console.log("User is blocked. Showing renewal reminder.");
          setShowRenewalReminder(true);
        } else {
          console.log("Redirecting to dashboard.");
          navigate("/dashboard");
        }
        
        
        } else {
          setLoading(false);
          toast.error("Please Enter Correct Mail/Password", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      } catch (error) {
        console.error("Error during login:", error);
        setLoading(false);
      }
    };

    if (showRenewalReminder) {
      return <RenewalReminderPage />;
    }
    
  return (
    <>
      <ToastContainer />
      {loading ? (
        <div style={{ height: "30rem" }}>
          <CSpinner style={{ position: "absolute", top: "50%", left: "48%" }} />
        </div>
      ) : (
        <>
          <CRow className="align-items-center justify-content-around">
            <CCol
              md={6}
              className="p-4 d-flex flex-column align-items-center form-container"
            >
              <h2 className="form-heading" style={{marginTop: "15px"}}>Welcome Back!</h2>
              <div className="d-flex flex-column align-items-center justify-content-around h-100">
                <form onSubmit={handleSubmit}>
                  <div className="form-inputGroup">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      onChange={(e) => setMail(e.target.value.toLowerCase())}
                      className="login-input"
                      autoComplete="off"
                      required
                    />
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      className="form-inputGroup_Icon"
                    />
                  </div>
                  <div className="form-inputGroup">
                    <input
                      type={pwdState ? "password" : "text"}
                      name="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="login-input"
                      autoComplete="off"
                      required
                    />
                    <FontAwesomeIcon
                      icon={pwdState ? faEye : faEyeSlash}
                      className="form-inputGroup_Icon"
                      style={{ cursor: "pointer" }}
                      onClick={togglePwdState}
                    />
                  </div>
                  <p className="login-haveTrouble">
                    
                    <CButton color="link" onClick={handleForgotPassword}>
                      <a href="#" style={{ textDecoration: "none", color: "inherit" }}>
                        Forgot Password? Reset Now
                        
                      </a>
                    </CButton>
                  </p>
                  {/* <a href="/dashboard"> */}
                  <CButton
                    color="dark"
                    shape="rounded-pill"
                    className="form-inputGroup_Button"
                    type="submit"
                    disabled={mail != "" && password != "" ? false : true}
                  >
                    Sign In
                  </CButton>
                  {/* </a> */}
                </form>
                <div className="form-inputGroup_Para w-100">
                  <span>Don't have an account?</span>
                  <a
                    className="form-inputGroup_Para-link" style={{marginLeft:"10px"}}
                    onClick={() => {
                      dispatch(setLoginPage("signUp"));
                    }}
                  >
                    SignUp here
                  </a>
                </div>
              </div>

              {/* <span className="login-or">--or--</span> */}
              {/* <button className="form-inputGroup_googleBtn">
              <i className="fab fa-google fa-2x"></i>
              Sign in with Google
            </button> */}
            </CCol>
            <div className="vr"></div>
            <CCol md={5} className="text-center">
              <Sliders />
            </CCol>
          </CRow>  
        </>
      )}
    </>
  );
}

export default SignIn;
